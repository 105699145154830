import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import Link from 'next/link';

import { toFooterSettingsShape } from 'lib/util/footerSettingsShape';
import { ReduxState } from 'ducks';
import { SnsIcons } from 'components/Sns/SnsIcons';

import { LanguageSelect } from './LanguageSelect';
import styles from './BookingWidgetFooter.module.css';
import baseStyles from '../base.module.css';
import { useEssentialPageLinks } from 'hooks/useEssentialPageLinks';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { useQueryString } from 'hooks/useQueryString';
import { useCustomFooterLinks } from 'hooks/useCustomFooterLinks';
import { appendQueryString } from 'lib/util/appendQueryString';
import { useCustomerLedgerEnabled } from 'hooks/useCustomerLedgerEnabled';

import { LoginItem } from './LoginItem';
import { useGuestMyPageEnabled } from 'hooks/useGuestMyPageEnabled';
import { useUrl } from 'hooks/useUrl';

const BookingWidgetFooter = () => {
  const { i18n, t } = useTranslation();

  const { useApiKeyInPaths, apiKey } = React.useContext(ApiKeyContext);

  const queryString = useQueryString();

  const settings = useSelector((state: ReduxState) =>
    toFooterSettingsShape(state.server.settings.all)
  );

  const isCustomerLedgerEnabled = useCustomerLedgerEnabled();

  const customFooterLinks = useCustomFooterLinks();

  const essentialPageLinks = useEssentialPageLinks();

  const isMyPageFeatureEnabled = useGuestMyPageEnabled();
  const showReservationsPage = isMyPageFeatureEnabled && !isCustomerLedgerEnabled;
  const reservationsUrl = useUrl('/reservations/verify');

  return (
    <footer className={clsx(styles['footer'], styles['custom'])}>
      <span className={baseStyles['color-footer-background']}></span>
      {(essentialPageLinks.length > 0 ||
        isCustomerLedgerEnabled ||
        (customFooterLinks?.length ?? 0) > 0) && (
        <div className={styles['footer__nav']}>
          <div className={styles['footer__nav__box']}>
            <ul className={styles['footer__nav__box__list']}>
              {(essentialPageLinks || []).map((link, idx) => (
                <li className={styles['footer__nav__box__list__item']} key={idx}>
                  <Link href={link.url}>{link.text}</Link>
                </li>
              ))}
              {showReservationsPage && (
                <li className={styles['footer__nav__box__list__item']}>
                  <Link href={reservationsUrl}>{t('Reservations')}</Link>
                </li>
              )}
              {(customFooterLinks || []).map((footerLink, idx) => (
                <li className={styles['footer__nav__box__list__item']} key={idx}>
                  {footerLink.custom_page_path ? (
                    <Link
                      href={appendQueryString(
                        useApiKeyInPaths
                          ? `/${apiKey}${footerLink.custom_page_path}`
                          : footerLink.custom_page_path,
                        queryString
                      )}
                    >
                      {footerLink.text}
                    </Link>
                  ) : (
                    <a href={footerLink.url} target="_blank" rel="noopener noreferrer">
                      {footerLink.text}
                    </a>
                  )}
                </li>
              ))}
              {isCustomerLedgerEnabled && <LoginItem />}
            </ul>
          </div>
        </div>
      )}
      <div className={clsx(styles['footer__inner'])}>
        <div className={styles['footer__info']}>
          <ul>
            <li>
              <picture>
                <img src="/static/images/ic_phone.svg" alt="" />
              </picture>
              {settings.phone}
            </li>
            <li>
              <picture>
                <img src="/static/images/ic_mail.svg" alt="" />
              </picture>
              {settings.email}
            </li>
          </ul>
          <ul>
            <li>
              <picture>
                <img src="/static/images/ic_location.svg" alt="" />
              </picture>
              {settings.address}
            </li>
            <li>
              <picture>
                <img src="/static/images/ic_time.svg" alt="" />
              </picture>
              {t('Hours: {{operatingHoursDescription}}', {
                operatingHoursDescription: settings.operatingHoursDescription,
              })}
            </li>
          </ul>
          <SnsIcons snsIds={settings?.snsIds} />
        </div>
        <div className={styles['footer__menu']}>
          <ul>
            {settings.showTravelAgencyRegistrationForm && (
              <>
                <li>
                  <a
                    href="https://ntmg.jp/registration.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('Travel Agency Registration')}
                  </a>
                </li>
                <li>
                  <a
                    href="https://ntmg.jp/agreement.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('Agreement')}
                  </a>
                </li>
              </>
            )}
            {i18n.language === 'ja-JP' && settings.showJapaneseTerms && (
              <li>
                <a href="https://app.ntmg.com/ja/terms" target="_blank" rel="noopener noreferrer">
                  特定商取引法
                </a>
              </li>
            )}
          </ul>
          <LanguageSelect />
        </div>
      </div>
      <p className={styles['footer__copy']}>© 2022 NutmegLabs Inc.</p>
    </footer>
  );
};

export default BookingWidgetFooter;
